import React from "react";
import { Link } from "react-router-dom";
import { slugify } from "../../../utils";

const PropOne = ({ projectStyle, portfolio, projectType }) => {
  return (
    <>
      <div className={`project-grid ${projectStyle}`}>
        <div className="thumbnail">
          {/* <Link to={process.env.PUBLIC_URL + `/project-details/${slugify(portfolio.title)}`}> */}
          <a href={`${portfolio.url}`} target="_blank">
            <img
              src={process.env.PUBLIC_URL + portfolio.image}
              alt="project image"
              className="opacityRemoval"
              style={{
                float: "left",
                width: "600px",
                height: "380px",
                objectFit: "cover",
                opacity: projectType === "food-photography" ? 1 : 0.3,
              }}
            />
          </a>
        </div>
        <div className="content">
          <span className="subtitle" style={{ color: "white" }}>
            {portfolio.category.map((cat, i) => (
              <span key={i}>{cat}</span>
            ))}
          </span>
          <h3 className="title" style={{ color: "white" }}>
            {portfolio.title}
            {/* <Link to={process.env.PUBLIC_URL + `/project-details/${slugify(portfolio.title)}`}> */}
            {/* </Link> */}
          </h3>
        </div>
        <p style={{ color: "white", marginTop: "10px" }}>{portfolio.excerpt}</p>
      </div>
    </>
  );
};

export default PropOne;
