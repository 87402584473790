import React from "react";

function Event() {
  return (
    <div>
      MY Webinars - Tag1
      <iframe
        width="100%"
        height="400"
        frameborder="0"
        src="https://app.livestorm.co/lstorm/?tag=Tag1"
        title="LStorm events | Livestorm"
      ></iframe>
    </div>
  );
}

export default Event;
