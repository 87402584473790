import React from "react";
import FormOne from "../contact/FormOne";
import Accordion from "react-bootstrap/Accordion";
import {
  FaCompress,
  FaCode,
  FaCameraRetro,
  FaFeatherAlt,
} from "react-icons/fa";

const AboutTwo = () => {
  return (
    <div style={{ padding: "50px 20px" }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="why-choose-us">
              <div className="section-heading heading-left">
                <span className="subtitle">About Me</span>
                <h3 className="title">Hey there, I'm Léa 👋</h3>
                <p>
                  Welcome to OhanaPixel, my personal portfolio.
                  <br />
                  I'm a full-stack developer with a passion for design and
                  entrepreneurship. I specialize in React.js, Next.js, Node.js,
                  MongoDB, and Tailwind CSS, and I'm always looking for new
                  challenges and opportunities to grow my skills.
                </p>
                <p>
                  In addition to my technical expertise, I'm also a food blogger
                  with a keen eye for detail and a love of all things culinary.
                  Whether I'm developing a new website or capturing the perfect
                  shot of a dish, I bring a creative and innovative approach to
                  everything I do.
                  <br />
                </p>
                <p>
                  Please take a look around, and feel free to contact me if you
                  have any questions or if you'd like to collaborate on a
                  project.
                  <br />
                </p>
              </div>
              <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <FaCode /> Web App Development
                  </Accordion.Header>
                  <Accordion.Body>
                    Static websites, e-commerce, full-stack web apps...
                    <br />
                    Here are a few technologies I&apos;ve been working on:
                    <ul>
                      <li>React.js, Next.js, Node.js, MongoDB, Express... </li>
                      <li>
                        CSS frameworks & React UI libraries: Tailwind CSS,
                        Bootstrap, Chakra UI
                      </li>
                      <li>CMS: Shopify (liquid), WordPress</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <FaCompress /> Digital Strategy
                  </Accordion.Header>
                  <Accordion.Body>
                    Whether you already have a digital presence or not, I can
                    help you identify ways to become more profitable with the
                    help of technology and develop a successful digital strategy
                    plan.
                  </Accordion.Body>
                </Accordion.Item> */}
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <FaFeatherAlt /> Web Design
                  </Accordion.Header>
                  <Accordion.Body>
                    With an eye for good design, I can help you plan, design and
                    develop your projects.
                  </Accordion.Body>
                </Accordion.Item>

                {/* <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    <FaCameraRetro /> Photography
                  </Accordion.Header>
                  <Accordion.Body>
                    If you're a foodie like me, let's grab a coffee so we can
                    discuss about our passion and Food Photography. To visit my
                    personal food's website, click{" "}
                    <a href="https://www.mamiesimone.fr/" target="_blank">
                      here
                    </a>
                    .
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 offset-xl-1 d-flex justify-content-center align-items-center">
            <img
              src={process.env.PUBLIC_URL + "/images/others/case-study-4.jpg"}
              alt="ohanapixel - coding"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
