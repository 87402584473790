import React from "react";
import HeaderThree from "../common/header/HeaderThree";
import SEO from "../common/SEO";
import BannerThree from "../component/banner/BannerThree";
import ProjectTwo from "../component/project/ProjectTwo";
import SectionTitle from "../elements/section-title/SectionTitle";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import BrandOne from "../component/brand/BrandOne";
import ProjectThree from "../component/project/ProjectThree";
import TestimonialTwo from "../component/testimonial/TestimonialTwo";
import FooterTwo from "../common/footer/FooterTwo";
import FormThree from "../component/contact/FormThree";
import AboutMe from "../component/about/AboutTwo";
import ReactGA from "react-ga4";

const PersonalPortfolio = () => {
  const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  return (
    <>
      <SEO title="OhanaPixel - Let's work together" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderThree />
        <BannerThree />
        <AboutMe />
        <ProjectTwo />
        <ProjectThree />

        <BrandOne />
        <TestimonialTwo />

        <div className="section-padding bg-color-dark" id="contact-me">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-address mb--30">
                  <SectionTitle
                    subtitle="Need a freelancer?"
                    title="Feel free to contact me"
                    description=""
                    textAlignment="heading-light-left"
                    textColor=""
                  />
                  <div className="address-list">
                    <div className="address">
                      <h6 className="title">Mail</h6>
                      <p>lea @ ohanapixel.com</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-form-box mb--30">
                  <h3 className="title">Describe your project</h3>
                  <FormThree />
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterTwo />
      </main>
    </>
  );
};

export default PersonalPortfolio;
