import React from "react";
import Event from "./pages/Event";
import EventTwo from "./pages/Event2";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";

import PersonalPortfolio from "./pages/PersonalPortfolio";

// Css Import
import "./assets/scss/app.scss";
import ReactGA from "react-ga4";

const App = () => {
  const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);
  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route
            path={process.env.PUBLIC_URL + "/"}
            element={<PersonalPortfolio />}
          />
          <Route path={process.env.PUBLIC_URL + "/event"} element={<Event />} />
          <Route
            path={process.env.PUBLIC_URL + "/event2"}
            element={<EventTwo />}
          />
        </Routes>
      </ScrollToTop>
    </Router>
  );
};

export default App;
