import React from "react";

function EventTwo() {
  return (
    <div>
      MY Webinars - Tag2
      <iframe
        width="100%"
        height="400"
        frameborder="0"
        src="https://app.livestorm.co/lstorm/?tag=Tag2"
        title="LStorm events | Livestorm"
      ></iframe>
    </div>
  );
}

export default EventTwo;
